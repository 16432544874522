// External Dependencies
import Container from '@mui/material/Container';
import React from 'react';

// Internal Dependencies
import HeaderGeneric from '../components/HeaderGeneric';
import HeadingWithAnchor from '../components/shared/HeadingWithAnchor';
import Layout from '../components/layout';
import Meta from '../components/Meta';

// Local Variables
const dataPointsWeCollect = [
  'First Name',
  'Last Name',
  'Email Address',
  'Date of Birth',
  'Gender',
  'Home Address',
  'Phone Number',
  'Grade',
];
const prestoCompanyName = 'Presto Assistant LLC';
const privacyPolicyMailToLink = `mailto:info@presto-assistant.com?subject=${encodeURIComponent('From the Presto Privacy Policy — Data Removal Request')}`;

// Component Definition
const PrivacyPolicy = () => (
  <Layout>
    <Meta title="Privacy Policy" />

    <HeaderGeneric title="Privacy Policy" />

    <div id="main">
      {/* eslint-disable max-len */}
      <section id="content" className="main">
        <Container maxWidth="md">
          <section className="flex-space-between">
            <h2>Presto Assistant LLC Privacy Policy</h2>

            <h5>Revised 3-26-2024</h5>
          </section>

          <p>Your privacy is important to us. It is {prestoCompanyName}&apos;s policy to respect your privacy regarding any information we may receive from you across our website, <a href="https://www.presto-assistant.com">https://www.presto-assistant.com</a>, and other sites we own and operate.</p>

          <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>

          <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.</p>
          <p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>

          <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>

          <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>

          <p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>

          <p>This policy is effective as of 11 August 2021.</p>

          <HeadingWithAnchor headingText="Data Security and Protection" />

          <p>
            {prestoCompanyName} is committed to ensuring the security of your personal information. To prevent unauthorized access, disclosure, copying, use, or modification, we safeguard the data we collect and store within commercially acceptable means. This includes employing a variety of security measures to protect your information both online and offline. All data is encrypted in transit, and access to your personal information is restricted to employees who need it to perform a specific job function. We also conduct regular security audits to ensure the ongoing integrity of our systems and data.
          </p>

          <p>
            In our dedication to protect your privacy and uphold the highest standards of data security, we have a National Data Privacy Agreement (NDPA) on file. The NDPA serves as a record to our commitment to adhering to stringent data privacy practices and regulations, ensuring the responsible handling and protection of your personal information.
          </p>

          <p>
            Email Security: We take the privacy and security of electronic communications seriously. All emails sent via the {prestoCompanyName} system are encrypted in transit, utilizing industry-standard encryption methods to protect your information from interception and unauthorized access during transmission.
          </p>

          <HeadingWithAnchor
            anchorText="coppa-compliance"
            headingText="Children Under 13"
          />

          <p>
            Children under the age of 13 are not permitted to use our website or create an account without parental consent. A subscribing organization to {prestoCompanyName} (both free trial and paid) may enter the following personal information:
          </p>

          <ul>
            {dataPointsWeCollect.map((dataPoint) => (
              <li key={dataPoint}>
                {dataPoint}
              </li>
            ))}
          </ul>

          <p>
            {prestoCompanyName} will not sell this information to third parties, nor will this information be used for marketing purposes. Any information obtained from your child will only be used for the benefit of your child and your child&apos;s organization(s) (e.g. band, choir, orchestra, etc.).
          </p>

          <p>
            {prestoCompanyName}  stores this data using only the services necessary. We do not share your information outside of the services we use. These services include:
          </p>

          <ul>
            <li>
              AWS and Heroku (database, web hosting, and file storage)
            </li>
            <li>
              Firebase (database for audit logs)
            </li>
            <li>
              Sendgrid (email sender)
            </li>
            <li>
              Stripe or Vanco RevTrak (online payment processing)
            </li>
          </ul>

          <p>
            It is the responsibility of the subscriber (both free trial and paid) to provide {prestoCompanyName} documentation of verifiable parental consent. A parent of a student under 13 may request for their child&apos;s data to be removed by sending an email to <a href={privacyPolicyMailToLink}>info@presto-assistant.com</a>.
          </p>
        </Container>
      </section>
      {/* eslint-enable max-len */}
    </div>
  </Layout>
);

export default PrivacyPolicy;
